<template>
  <div class="login-wrap">
    <div class="i-login-back-head">
      <div class="i-back-head-img"></div>
      <span class="i-back-head-text">注册账号</span>
      <div class="i-back-head-right-text"></div>
    </div>
    <div class="login-content">
        <div class="ant-row ant-form-item ant-form-item-has-success">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div class="phone-input">
                  <input 
                    v-model="phone"
                    autocomplete="off" 
                    class="ant-input i-input" 
                    placeholder="手机号" 
                    type="text"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div style="position: relative">
                  <input
                    v-model="smscode"
                    class="ant-input i-input"
                    placeholder="验证码"
                    maxlength="10"
                    type="text"/>
                  <button
                    @click="sendSmsCode"
                    type="button"
                    class="ant-btn ant-btn-link"
                    style="position: absolute;right: 0px;top: 0px;height: 44px;"
                    :disabled="downCount > 0">
                    <span v-if="downCount > 0">{{downCount}}s 后重发</span>
                    <span v-else>获取验证码</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-readonly ant-input-password i-input">
                  <input
                    v-model="password"
                    placeholder="设置密码"
                    action="click"
                    type="password"
                    class="ant-input" 
                    autocomplete="new-password"/>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item" style="padding-top: 2px">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <label class="ant-checkbox-wrapper remember">
                  <span class="ant-checkbox">
                    <input v-model="isRead" type="checkbox"/>
                  </span>
                  <span>
                    我已阅读并勾选<router-link tag="a" target="_blank" :to="{path:'/userAgreement'}">《用户协议》</router-link>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item" style="padding-top: 2px">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <button
                  @click="register"
                  class="ant-btn i-button button-red"
                  style="width: 100%"
                  :disabled="!isRead">
                  <span>注 册</span>
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {setUserInfo} from '@/utils';

export default {
  name: "register",
  data() {
    return {
      phone: '',
      smscode: '',
      password: '',
      downCount: 0,
      isRead: false,
      smstype: 2
    }
  },
  methods: {
    sendSmsCode(){
      this.$http.post('/api/sendSmsCode',{phone: this.phone,smstype: this.smstype}).then(()=>{
        this.$Message.success("验证码发送成功");
        clearTimeout(this.downCountTimeOut);
        this.downCount = 60;
        this.startDownCount();
      });
    },
    startDownCount(){
      this.downCountTimeOut = setTimeout(() => {
         if(this.downCount > 0){
            this.downCount --;
            this.startDownCount();
         }
      }, 1000);
    },
    register(){
      this.$http.post('/api/registerWithSmsCode',{username: this.phone,smscode: this.smscode,smstype: this.smstype,password: this.password}).then(data=>{
        let backName = this.$route.query.backName || 'account';
        setUserInfo(data);
        this.$router.push({name: backName});
      });
    }
  }
};
</script>
<style scoped>
.register {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>